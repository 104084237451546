

<template>
<v-form ref="form">
  <div>
    <contract-Reservation-Point-Detail>
      <!-- Title -->
      <template v-slot:title>新規予約作成 {{clientName}} 顧客番号{{clientMemberId || 'なし'}}</template>
      <!-- End Title -->
      <template v-slot:cancel>
        <v-btn class="t-btn--red-dark btn-cancel-cus" @click="$windowClose">
          <v-icon>mdi-close</v-icon>
          <span>閉じる</span>
        </v-btn>
      </template>


      <template v-slot:send>
        <v-btn small class="t-btn--prm ml-3 btn-active-cus px-4" @click="save" :disabled="!checkPerUser" :loading="saving">
          新規予約作成
        </v-btn>
      </template>
      <!-- Create a new reservation  -->
      <!-- Content  -->
      <template v-slot:default>
        <template>
         <v-row style="margin-top: 2%;">
          <!-- History -->
          <div style="width: 40%;" class="mb-16" >
            <v-card
              class="pa-5 ml-6 mt-3 v-card-responsive-table size-component"
              style="margin-left: 6% !important;"
              color="#FAFAFA"
            >
              <v-row style="padding: 20px;">
                <v-col :cols="12">
                    <v-row class="d-flex">
                        <div class="mb-3">
                            <h4 class="t-10-c-black text-cus">
                            利用契約
                            </h4>
                            <span>{{$route.params.type}} 提携</span>
                        </div>
                    </v-row>
                    <v-row class="d-flex">
                        <div class="d-flex" style="width: 100%;">
                            <div class="selectCustom">
                                <h4 class="t-10-c-black text-cus">
                                施設を選択
                                </h4>
                                <template>
                                <v-select
                                    dense
                                    v-model="getIdFacilityNReg"
                                    :items="facilityListNotificationRegistrationByPT"
                                    item-value="id"
                                    item-text="name"
                                    :rules="rules.required"
                                    :disabled="!checkPerUser"
                                ></v-select>
                                </template>
                            </div>
                            <div class="selectCustom">
                                <h4 class="t-10-c-black text-cus">
                                部屋タイプ
                                </h4>
                                <template>
                                <v-select
                                    dense
                                    v-model="getIdRoomTypeNReg"
                                    :items="roomTypeListNReg"
                                    item-value="id"
                                    item-text="name"
                                    :rules="rules.required"
                                    :disabled="!checkPerUser"
                                ></v-select>
                                </template>
                            </div>
                        </div>
                    </v-row>
                    <v-row class="d-flex" style="margin-bottom: 12px;margin-left: -22px;">
                        <div class="d-flex align-center">
                          <div
                            class="d-flex align-center"
                            v-for="item in listDate"
                            :key="item.id"
                          >
                            <span v-if="item.id == 2" class="mx-1 noto-sans_16px ml-1 mr-1 mt-5">〜</span>
                            <v-menu
                              v-model="menuDate[listDate.indexOf(item)]"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                              :disabled="!checkPerUser"
                            >
                              <template v-slot:activator="{ on }">
                                <div>
                                  <div>
                                      <span class="ml-10 date-cus" v-if="item.id == 1">チェックイン</span>
                                      <span class="ml-10 date-cus" v-else>チェックアウト</span>
                                  </div>
                                  <div style="font-size: 10px !important;">
                                    <v-icon>mdi-calendar-month</v-icon>
                                    <v-btn
                                    outlined
                                    small
                                    color="var(--text_gray)"
                                    class="bg--white date-cus"
                                    v-model="item.date"
                                    hide-details
                                    v-on="on"
                                    :disabled="!checkPerUser"
                                    >{{ formatDate(item.date) }}</v-btn>
                                  </div>
                                </div>
                              </template>
                              <div v-if="item.id == 1">
                                <div style="border:1px solid black;font-size: 10px !important;">
                                  <v-date-picker
                                    v-model="item.date"
                                    :first-day-of-week="0"
                                    :locale="$i18n.locale"
                                    scrollable
                                    @input="menuDate[listDate.indexOf(item)] = false"
                                    @change="setCheckOutDate(item.date)"
                                    class="v-date-picker-custom date-cus"
                                    :disabled="!checkPerUser"
                                  ></v-date-picker>
                                </div>
                              </div>
                              <!-- Date to -->
                            <v-date-picker
                                v-else
                                v-model="item.date"
                                :first-day-of-week="0"
                                :min="minCheckOutDate"
                                :locale="$i18n.locale"
                                scrollable
                                @input="menuDate[listDate.indexOf(item)] = false"
                                class="v-date-picker-custom"
                                :disabled="!checkPerUser"
                              ></v-date-picker>
                          </v-menu>
                          </div>
                        </div>
                        <span class="custom-date dayNight" style="height: 20px;padding-top: 25px;margin-left: 10px;">{{parseInt(night)}}泊{{parseInt(days)}}日</span>
                    </v-row>
                     <v-row>
                        <v-col :cols="3" class="selectCustomShort">
                                <h4 class="t-10-c-black text-cus">
                                大人
                                </h4>
                                <template>
                                <v-select
                                    dense
                                    value="0"
                                    v-model="numberOfAdults"
                                    :items= arrayNumberSelected
                                    :rules="rules.required"
                                    :disabled="!checkPerUser"
                                ></v-select>
                                </template>
                            </v-col>
                            <v-col :cols="3" class="selectCustomShort">
                                <h4 class="t-10-c-black text-cus">
                                子供(非添い寝)
                                </h4>
                                <template>
                                <v-select
                                    dense
                                    value="0"
                                    v-model="numberOfChildren"
                                    :items= arrayNumberSelected
                                    :rules="rules.required"
                                    :disabled="!checkPerUser"
                                ></v-select>
                                </template>
                            </v-col>
                            <v-col :cols="3" class="selectCustomShort">
                                <h4 class="t-10-c-black text-cus">
                                <!-- {{ $t('task_29786.CustomerMembership.UsageFee') }} -->
                                子供(添い寝)
                                </h4>
                                <template>
                                <v-select
                                    dense
                                    value="0"
                                    v-model="numberOfChildrenWithBedShare"
                                    :items= arrayNumberSelected
                                    :rules="rules.required"
                                    :disabled="!checkPerUser"
                                ></v-select>
                                </template>
                          </v-col>
                    </v-row>
                     <v-row class="d-flex">
                        <div class="mb-3">
                            <h4 class="t-10-c-black text-cus">
                            <span>利用 {{$route.params.type}}</span>
                            </h4>
                            <span>{{pointNumber}}pt</span>
                        </div>
                    </v-row>
                </v-col>
              </v-row>
              <!--  -->
            </v-card>
          </div>
          <!-- End History -->
          <div style="width: 60%;" >
              <v-row style="margin-left: 5%;">
                <v-col :cols="6">
                  <div class="mb-2">
                        <div>
                            <label class="text-cus">予約バーに表示させる備考内容</label>
                            <v-text-field
                                style="width:50%"
                                v-model="bookingBarNote"
                                class="text-cus"
                                :disabled="!checkPerUser"
                                :rules="[$rules.checkLenghInput(255)]"
                            ></v-text-field>
                        </div>
                        <div class="selectCustom">
                            <h4 class="t-10-c-black">
                            宿泊代表者
                            </h4>
                            <template>
                              <v-combobox
                                dense
                                v-model="getIdClientInfo"
                                class="my-5"
                                item-value="id"
                                item-text="name"
                                :items="clientMemberInfoNotificationRegistrationByPT"
                                @change="changeClientMemberInfo(getIdClientInfo)"
                                :rules="rules.required"
                                :disabled="!checkPerUser"
                              ></v-combobox>
                            </template>
                        </div>
                        <div class="selectCustom mt-3">
                            <v-text-field
                                v-model="nameKana"
                                label="宿泊代表者ヨミガナ"
                                class="text-title pt-0"
                                :rules="[$rules.checkKatakana, $rules.checkLenghInput(255)]"
                                :disabled="!checkPerUser"
                            ></v-text-field>
                            
                        </div>
                        <div class="selectCustom mt-3">
                            <!-- <v-text-field
                                v-model="PhoneNumber"
                                label="電話番号"
                                class="text-title pt-0"
                                :rules="[$rules.checkNumber, $rules.checkLenghInput(15)]"
                                :disabled="!checkPerUser"
                            ></v-text-field> -->
                            <v-text-field
                                v-model="PhoneNumber"
                                label="電話番号"
                                class="text-title pt-0"
                                :disabled="!checkPerUser"
                            ></v-text-field>
                            
                        </div>
                        <div class="selectCustom mt-3">
                            <v-text-field
                                v-model="email"
                                label="メールアドレス"
                                class="text-title pt-0"
                                :rules="[$rules.isEmailValid]"
                                :disabled="!checkPerUser"
                            ></v-text-field>
                            
                        </div>
                        <div class="selectCustom mt-3">
                            <v-select
                              :disabled="!checkPerUser"
                              class="text-select pt-0"
                              label="国を選択"
                              :items="itemRepresentativeCountry"
                              v-model="representativeCountry"
                              :rules="[$rules.checkLenghInput(255)]"
                          ></v-select>
                            
                        </div>
                        <div class="selectCustom mt-3">
                          <v-text-field
                              :disabled="!checkPerUser"
                              class="text-field pt-0"
                              label="郵便番号"
                              v-model="representativePostalCode"
                              :rules="[$rules.postalCodeNoHyphen]"
                          ></v-text-field>
                            
                        </div>
                        <div class="selectCustom mt-3">
                            <v-text-field
                                v-model="Address"
                                label="住所"
                                class="text-title pt-0"
                                :rules="[$rules.checkLenghInput(255)]"
                                :disabled="!checkPerUser"
                            ></v-text-field>
                            
                        </div>
                    </div>
                </v-col>
              </v-row>
          </div>
          </v-row>
        </template>
      </template>
      <!-- End Content  -->
    </contract-Reservation-Point-Detail>
  </div>
</v-form>
</template>

<script>
import contractReservationPointDetail from '../ReservationPointDetail.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { formatDate, handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import { checkNumber , isEmailValid } from '@/utils/validate';
import router from "@/router";
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { BOOKING_TYPE_LIST } from '@/api/graphql/bookingTicketType';
import gql from 'graphql-tag'
import {getISODate, getDiff2Days} from '@/utils/dateUtil'
import {getOneLineAddress} from '@/utils/string';

const add1Day = (date) => {
  const result = new Date(date);
  result.setDate(result.getDate() + 1);
  return result;
}

export default {
  name: 'ReservationPointAffiliatedFacility',
  data() {
    return {
      bookingTypeList: [],
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      menuDate: [],
      listDate: [
        { id: 1, date: getISODate(new Date()) },
        { id: 2, date: getISODate(add1Day(new Date())) },
      ],
      itemRepresentativeCountry: ['日本', 'その他'],
      representativeCountry: null,
      representativePostalCode: null,
      arrayNumberSelected:['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20'],
      pointNumber:0,
      PhoneNumber:null,
      numberOfAdults:null,
      numberOfChildren:0,
      numberOfChildrenWithBedShare:0,
      bookingBarNote:null,
      Address:null,
      contractID: null,
      contacts:[],
      subMember:[],
      nameKana:null,
      name: null,
      CheckChangePointInput:false,
      email:null,
      createdSubMemberId:null,
      representativeClientId:null,
      representativeSubMemberId:null,
      dateNow: new Date().getFullYear() + '-' + ((new Date().getMonth()+1) < 10 ? '0'+(new Date().getMonth()+1) : (new Date().getMonth()+1)) + '-' + (new Date().getDate() < 10 ? '0'+new Date().getDate() : new Date().getDate()),
      rules: {
        rulesEmail: [
          (v) =>
            !v ||
            isEmailValid(v) ||
            this.$t('rules.emailIsInvalid'),
        ],
        number: [ v => !!v || this.$t('rules.requiredField'),
          v => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          v =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32'),],
        required: [(v) => !!v || '必須項目です。'],
        // text: [
        //   v =>
        //     (v && (CheckLenghInput(v, 255))) ||
        //     !v ||
        //     this.$t('rules.maximumNCharacter', { value: 255 }),
        // ],
        PhoneNumber:[
          v => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          // v =>
          //   (v && (CheckLenghInput(v, 255))) ||
          //   !v ||
          //   this.$t('rules.maximumNCharacter', { value: 255 }),
        ]
      },
      clientName: null,
      clientMemberId: null,
      saving: false
    };
  },
  computed: {
    ...mapGetters(['getPointInput',
    'facilityListNotificationRegistrationByPT',
    'idFacilityNReg',
    'idClientInfoNReg',
    'roomTypeListNReg',
    'idRoomTypeNReg',
    'clientMemberInfoNotificationRegistrationByPT',
    ]),
    // filter idFacilityNReg
    getIdFacilityNReg: {
      get() {
        return this.idFacilityNReg;
      },
      set(value) {
        this.setIdFacilityNReg(value);
        this.getRoomTypeListNotificationRegistration();
      },
    },
    // filter idRoomTypeNReg
    getIdRoomTypeNReg: {
      get() {
        return this.idRoomTypeNReg;
      },
      set(value) {
        this.setIdRoomTypeNReg(value);
      },
    },
    // filter IdClientInfoNReg
    getIdClientInfo: {
      get() {
        return this.idClientInfoNReg ? this.idClientInfoNReg : null;
      },
      set(value) {
        this.setIdClientInfoNReg(value);
      },
    },
    minCheckOutDate () {
      return getISODate(add1Day(this.listDate[0].date))
    },
    days () {
      return this.night + 1
    },
    night () {
      return getDiff2Days(this.listDate[0].date, this.listDate[1].date)
    },
    checkInDate () {
      return this.listDate[0].date
    },
    checkOutDate() {
      return this.listDate[1].date
    }
  },
  async created() {
    this.getBookingTypeList()
    this.pointNumber = parseInt(router.currentRoute.params.point);
    this.CheckChangePointInput = this.getCheckChangePointInput
    this.contractID = parseInt(router.currentRoute.params.id);
    this.getFacilityListNotificationRegistrationByPT();
    this.setClientIdNreg(Number(this.contractID));
    const result = await this.getClientMemberInfoNotificationRegistrationByPT(parseInt(this.$route.query.clientId));
    this.representativeClientId = parseInt(this.$route.query.clientId)
    this.clientName = result.name;
    this.clientMemberId = result.memberId
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    formatDate,
    ...mapActions(['ActionCreateBookingV4',
    'getFacilityListNotificationRegistrationByPT',
    'getRoomTypeListNotificationRegistration',
    'getClientMemberInfoNotificationRegistrationByPT']),
    ...mapMutations([
      'setCopyErrorText',
      'setIdFacilityNReg',
      'setIdRoomTypeNReg',
      'setClientIdNreg',
      'setIdClientInfoNReg',
      'setAlertSuccess',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),
    setCheckOutDate (checkInDate) {
      if (this.listDate[1].date <= checkInDate) {
        this.listDate[1].date = getISODate(add1Day(checkInDate))
      }
    },
    changeClientMemberInfo(val){
      let item = {}
      if (val.id || val.ClientId || val.clientId){
        item = (this.clientMemberInfoNotificationRegistrationByPT ?? []).find(
          (i) => i.id === val.id,
        );
      } else {
        item.name = val
      }
      this.nameKana = item.nameKana ? item.nameKana : null
      this.PhoneNumber = item.tel ? item.tel : null
      this.email = item.email ? item.email : null
      this.representativePostalCode = item.postalCode || null
      this.Address = getOneLineAddress(item)
      this.name = item.name ? item.name : null
      this.createdSubMemberId = item.id ? item.id : null
      this.representativeSubMemberId = item.id ? item.id : null
    },
    async save(){
      if (this.saveing) return
      this.saving = true
      try {
        const datafinal = {
          bookingTypeId : 12,
          contractId: this.contractID,
          points: parseInt(this.pointNumber),
          roomTypeId:  this.getIdRoomTypeNReg,
          checkInDate: this.checkInDate,
          createdClientId: parseInt(this.$route.query.clientId),
          createdSubMemberId: this.createdSubMemberId,
          bookingBarNote: this.bookingBarNote, 
          representativeClientId: this.representativeClientId,
          representativeSubMemberId: this.representativeSubMemberId,
          representativeName: this.name, 
          representativeKana: this.nameKana,
          representativeTel: this.PhoneNumber,
          representativeEmail: this.email,
          representativeAddress: this.Address,
          numberOfAdults: parseInt(this.numberOfAdults),
          numberOfChildren: parseInt(this.numberOfChildren),
          representativeCountry: this.representativeCountry,
          representativePostalCode: this.representativePostalCode,
          numberOfChildrenWithBedShare: parseInt(this.numberOfChildrenWithBedShare),
          days: [...Array(this.night)].map(() => ({}))
        };
        if ( this.$refs.form.validate()) {
          const finalize = () => this.ActionCreateBookingV4(datafinal).then((result) => {
            this.redirectPagePMS(result)
          });

          if (this.checkInDate < getISODate(new Date())) {
            // if checkInDate is in the past, show confirm dialog TO2020-937
            this.$confirm({
              message: '過去の日付で予約を作成します。よろしいですか？',
              ok: finalize,
            })
          } else {
            await finalize()
          }
        }
      } finally {
        this.saving = false
      }
    },
    async getBookingTypeList () {
      await this.$apollo.query({
        query: gql`${BOOKING_TYPE_LIST}`
      }).then(data => {
        this.bookingTypeList = data.data.bookingTypesList
      }).catch(async (error) => {
        this.setCopyErrorText(this.getCopyErrorTextView(BOOKING_TYPE_LIST, null, error.graphQLErrors))
        const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },
    redirectPagePMS (item) {
      if (item) {
        const urlPMS =  process.env.VUE_APP_URL_PMS
        const routeData = urlPMS + '/reservation/contract-detail?id=' +
                      item.id + '&fromPage=crm&tab=basic-information'
        window.open(routeData)
        window.close()
      }
    }
  },
  components: {
    contractReservationPointDetail,
  },
};
</script>

<style lang="scss" scoped>
.table-primary::v-deep tbody > tr:hover {
  background: #ffffff !important;
}

.text-title-component {
  font-size: 20px;
  color: #004f8b;
  font-weight: bold;
}
.text-title-span {
  font-size: 28px;
  color: #004f8b;
  font-weight: 500;
}
.text-center {
  .v-btn--icon {
    background-color: #3083dc !important;
  }
}
.t-btn--prm,
.t-btn--red-dark {
  font-size: 14px !important;
  height: 32px !important;
}
::v-deep {
  
  .v-card {
    .v-size--small {
      .v-btn__content {
        font-size: 10px !important;
        font-weight: 500;
      }
    }
    .v-btn:not(.v-btn--round).v-size--small {
      height: 32px !important;
    }
  }
  .btn-crm-primary {
    .v-btn__content {
      font-size: 12px !important;
      color: #1873d9;
    }
  }
  .theme--light.v-input {
      .v-input__control {
        input {
          font: normal normal medium 14px/21px Noto Sans;
          font-size: 14px !important;
          letter-spacing: 0px;
          color: #444444 !important;
          opacity: 1;

        }
        
      }
    }
}
.v-data-table::v-deep {
  th,
  .v-select__selection {
    font-size: 16px !important;
    color: #424242 !important;
    font-weight: 400 !important;
  }
  tbody {
    tr {
      td {
        color: #424242 !important;
        font-weight: 400 !important;
        font-size: 16px !important;
      }
    }
  }
  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 12px !important;
        }
      }
    }
    .v-icon__svg {
      color: #333333 !important;
    }
  }
  .v-data-footer__pagination {
    display: none !important;
  }
}
.size-component {
  width: 100%;
}
.btn-icon {
  background-color: #3083dc;
}

.btn-add {
  min-width: 92px;
  height: 24px;
  color: #1873d9 !important;
  font-size: 12px;
  border: #1873d9 1px solid !important;
}

.text-diff-part {
  color: #3083dc;
}

.custom-dialog {
  label {
    width: 120px !important;
    flex-shrink: 0;
    text-align: right;
  }
}
.w-75 {
  width: 75%;
}

.selectCustom {
  width: 100%;
  margin-right: 10px;
}
.selectCustomShort {
  width: 25%;
  margin-right: 10px;
}
.v-card{
    &.v-sheet {
        &.theme--light{
            background-color: red 0% 0% no-repeat padding-box !important;
        }
    }
}
.custom-date{
  color: #000000 !important;
}
.date-cus{
  text-align: center;
  font: normal normal medium 10px/12px Noto Sans;
  font-size: 10px !important;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}
.dayNight{
  text-align: left;
  font: normal normal medium 16px/21px Noto Sans;
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}
.text-cus{
  font: normal normal medium 10px/12px Noto Sans;
  font-size: 10px !important;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}
.v-label{
    font: normal normal medium 10px/12px Noto Sans;
    font-size: 10px !important;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
}
.btn-active-cus{
  width: 120px;
  height: 32px;
  background: #1873D9 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  opacity: 1;
}
.btn-cancel-cus{
  width: 80px;
  height: 32px;
  background: #C92D2D 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  opacity: 1;
}
.text-title::v-deep label {
  font-size: 10px !important;
  color: #000000 !important;
}
.text-title::v-deep input {
  font-size: 14px !important;
  color: #000000 !important;
}
</style>