<template>
  <div>
    <div class="py-5 px-10 header-main d-flex justify-space-between">
      <div class="d-flex justify-space-between w-100">
        <h1 class="page-title-main d-flex justify-space-between">
          
          <div>
            <slot name="title"></slot>
            <slot name="btn"></slot>
          </div>
        </h1>
        <div class="d-flex justify-end">
          <slot name="cancel"></slot>
          <slot name="send"></slot>
        </div>
      </div>
      <slot name="btn-right"></slot>
    </div>
    <!-- <contract-detail-tab></contract-detail-tab> -->
    <slot name="default"></slot>
  </div>
</template>
<script>

export default {
  components: { },
  name: 'contract-Reservation-Point-Detail',
  data() {
    return {
      tab: 0,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.header-main {
  background-color: #f5f5f5 !important;
  .btn-crm-primary {
    border: 0.5px solid #1873d9;
  }
}
</style>
